import { reactive, watch } from 'vue';
import { capitalize } from 'lodash/string';
import { useStorage } from 'vue3-storage';

export const formatDateToUTC = (input: string): string => {
  const date = new Date(input);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC',
  };
  let formattedDate = date.toLocaleString('en-US', options);

  formattedDate = formattedDate.replace(/, (\d)/, ' $1').replace(' at ', ', ');

  return `${formattedDate} UTC`;
};

/**
 * Checks for a valid YYYY-MM-DD formatted string
 *
 * @param dateString
 */
export const isValidDate = (dateString: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
};

/**
 * @function
 * @name cleanObject
 * @param {Object} object - The object to be cleaned
 * @returns {Object} - The cleaned object without null values and empty arrays
 */
export const cleanObject = (object) => {
  const cleanedEntries = Object.entries(object).map(([key, value]) => {
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      return [key, cleanObject(value)];
    }

    return [key, Array.isArray(value)
      ? value.filter((arrayValue) => arrayValue !== null && arrayValue.length !== 0)
      : value
    ];
  });

  return Object.fromEntries(cleanedEntries.filter(([_, value]) => value !== null && value.length !== 0));
};

export const convertProxyToArray = (object) => JSON.parse(JSON.stringify(object));

export const isNumberType = (value) => typeof value === 'number' && !Number.isNaN(value);

export const isNumber = (event) => {
  // eslint-disable-next-line no-param-reassign
  event = (event) || window.event;
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    event.preventDefault();
  }
  return true;
};

/**
 * @deprecated Use Vue's defineModel to pass mutable props instead of defineProps and instead of using reactify.
 */
export const reactify = (thisComp) => {
  const {props, emit} = thisComp;
  const propKeys = Object.keys(props);
  const _localObject = Object.assign({}, props); // Mutable props
  const localObject = {};

  if (!thisComp.emitsOptions) {
    thisComp.emitsOptions = {};
  }

  propKeys.forEach((prop) => {
    thisComp.emitsOptions[`update:${prop}`] = null;
    watch(() => props[prop], (newValue) => {
      if (localObject[prop] === newValue) return;

      _localObject[prop] = newValue;
    });

    Object.defineProperty(localObject, prop, {
      get() {
        return _localObject[prop];
      },
      set(value) {
        _localObject[prop] = value;
        emit(`update:${prop}`, value);
      },
    });
  });

  return reactive(localObject);
};

export const truncate = (value, amount) => {
  const subAmount = amount - 3;
  if (value && value.length > amount) {
    value = value.substring(0, subAmount) + '...';
  }
  return value;
};

export const keyToText = (key: string) => capitalize(key.replace(/[_-]/g, ' '));

export const arrayCompare = (a1, a2) => {
  if (a1.length != a2.length) return false;
  const length = a2.length;
  for (let i = 0; i < length; i += 1) {
    if (a1[i] !== a2[i]) return false;
  }
  return true;
};

export const inArray = (needle, haystack) => {
  const length = haystack.length;
  for (let i = 0; i < length; i += 1) {
    if (typeof haystack[i] == 'object') {
      if (arrayCompare(haystack[i], needle)) return true;
    } else if (haystack[i] == needle) return true;
  }
  return false;
};

export const uppercaseWords = (str: string) => {
  const words = str.split(' ');

  for (let i = 0; i < words.length; i += 1) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};

export const removeSlashes = (str: string) => str.replace(/\\/g, '');

export const customLabels: { label: string; value: number }[] = [
  {label: 'Custom Label 0', value: 0},
  {label: 'Custom Label 1', value: 1},
  {label: 'Custom Label 2', value: 2},
  {label: 'Custom Label 3', value: 3},
  {label: 'Custom Label 4', value: 4},
];

export function getSelectedMerchantsInMerchantWizard() {
  const storage = useStorage();
  const storageMerchants = storage.getStorageSync('merchant_wizard_merchants_with_mappings');
  const actuallySelectedMerchants = storage.getStorageSync('merchant_wizard_actually_selected_merchants');
  const selectedIds = actuallySelectedMerchants.map((merchant) => merchant.merchant_id);
  return storageMerchants.filter((merchant) => selectedIds.includes(merchant.merchant_id));
}

export const findInSet = (needle: string, haystack: Array<string>, property: string) => haystack.find((straw) => straw[property] == needle);
